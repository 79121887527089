import TaskTable from "@components/automation/TaskTable";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useGetAllTasks } from "@store/actions/taskActions";
import React from "react";

function TasksPage() {
  const { data, loading, refetch } = useGetAllTasks({
    status: "pending",
  });

  if (loading) {
    return <CircularProgress size={80} color="primary" />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" onClick={() => refetch()}>
          Refresh Page
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TaskTable tasks={data.task || []} onRefetch={refetch} />
      </Grid>
    </Grid>
  );
}

export default TasksPage;
