import { BookingLink, InfoCol } from "@components/booking/BookingsTableInner";
import CardTable from "@components/cardTable/CardTable";
import Chat from "@components/chat/Chat";
import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useLazyGetAllMessagesByLad,
  useSendLeadMessage,
  useUpdateMessage,
} from "@store/actions/messageActions";
import { useMarkAsDone } from "@store/actions/taskActions";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { friendlyDateTime } from "utils/date";
import { camelToText, truncate } from "utils/strings";
import TaskNewBookingOptions from "./TaskNewBookingOptions";
import {
  useCreateBookingOption,
  useUpdateBookingOption,
} from "@store/actions/bookingActions";
import { useHistory } from "react-router-dom";
import {
  useCreateQuote,
  useCreateQuoteItem,
} from "@store/actions/quoteActions";

const Row = (onRefetch) => ({ row: { ref } }) => {
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [chatOpen, setChatOpen] = useState(false);
  const [chatUseAi, setChatUseAi] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [getMessages, { data, loading }] = useLazyGetAllMessagesByLad();
  const [sendLeadMessage] = useSendLeadMessage(ref.booking?.id || null, null);
  const [updateMessage] = useUpdateMessage();
  const { showNotification } = useNotificationContext();
  const [mapiMarkAsDone] = useMarkAsDone(ref.id);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addBookingOption] = useCreateBookingOption(ref.booking || {});
  const [addQuote] = useCreateQuote(ref.booking || {});
  const [addQuoteItem] = useCreateQuoteItem();

  const [updateBookingOption] = useUpdateBookingOption();
  const history = useHistory();

  const messages = useMemo(() => {
    return data?.messagesByLead || [];
  }, [data]);

  const aiResponse = useMemo(() => {
    return ref.description && ref.description !== ""
      ? ref.description
      : "No AI Response";
  }, [ref]);

  const onOpenChat = useCallback(
    (useAI) => {
      getMessages({ variables: { leadID: ref.lead.id } });
      setChatUseAi(useAI);
      setChatOpen(true);
    },
    [ref, aiResponse]
  );

  const viewMsg = useCallback(() => {
    openConfirmation({
      title: `Message from ${ref.lead.email}`,
      body: ref.message?.content || "",
      callback: closeConfirmation,
    });
  }, [ref]);

  const viewResponse = useCallback(() => {
    openConfirmation({
      title: `${ref.name}`,
      body: aiResponse,
      callback: closeConfirmation,
    });
  }, [ref, aiResponse]);

  const onChatClose = useCallback(() => {
    setChatOpen(false);
  }, []);

  const onChatSend = useCallback(
    async (content) => {
      let input = {
        content,
        sendSms,
      };

      if (ref.booking?.id) {
        input.booking = {
          id: ref.booking?.id,
        };
      }
      // if (option?.id) {
      //   input.bookingOption = {
      //     id: option.id,
      //   };
      // }
      // if (quote?.id) {
      //   input.quote = {
      //     id: quote.id,
      //   };
      // }
      try {
        await sendLeadMessage({
          variables: {
            input,
            toLeadID: ref.lead.id,
          },
        });

        showNotification("Message sent");
      } catch (error) {
        console.error(error);
      }
    },
    [ref, sendSms]
  );

  const setMessageAsSpam = useCallback(async () => {
    if (!ref.message?.id) {
      return;
    }
    const input = {
      content: ref.message?.content || "",
      type: "spam",
    };
    await updateMessage({
      variables: {
        id: ref.message?.id,
        input,
      },
    });
    onRefetch();
  }, [ref, onRefetch]);

  const createOptions = useCallback(async () => {
    if (!ref.booking?.id) {
      return;
    }

    const {
      data: { createBookingOption },
    } = await addBookingOption({
      variables: {
        bookingID: ref.booking.id,
      },
    });

    if (selectedOptions.length > 0) {
      const items = selectedOptions.map((boat) => {
        return {
          price: boat.calculatedQuotePrice || undefined,
          hours: ref.booking.hours,
          boat: {
            id: boat.id,
          },
        };
      });

      await updateBookingOption({
        variables: {
          id: createBookingOption.id,
          input: {},
          items,
        },
      });
    }
    showNotification("Options Created");
    history.push(
      `/bookings/${ref.booking.id}/options/${createBookingOption.id}`
    );
  }, [ref, selectedOptions]);

  const createQuote = useCallback(async () => {
    if (!ref.booking?.id) {
      return;
    }

    const {
      data: { createQuote },
    } = await addQuote({
      variables: {
        bookingID: ref.booking.id,
        type: "standard",
      },
    });

    if (selectedOptions.length > 0) {
      await Promise.all(
        selectedOptions.map(async (boat) => {
          const input = {
            baseCost: boat.calculatedQuotePrice || undefined,
            boat: {
              id: boat.id,
            },
          };

          await addQuoteItem({
            variables: {
              quoteID: createQuote.id,
              input,
            },
          });
        })
      );
    }

    showNotification("Quote Created");

    history.push(`/bookings/${ref.booking.id}/quotes/${createQuote.id}`);
  }, [ref, selectedOptions]);

  const markAsDone = useCallback(async () => {
    await mapiMarkAsDone();
    onRefetch();
  }, [onRefetch, mapiMarkAsDone]);

  useEffect(() => {
    if (!chatOpen) {
      setChatUseAi(false);
    }
  }, [chatOpen]);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 350 }}>
          <Typography variant="subtitle2" gutterBottom>
            Task Created {friendlyDateTime(ref.createdAt)}
          </Typography>
          <BookingLink
            row={{
              ...ref.booking,
              lead: {
                ...ref.lead,
              },
            }}
          />
        </TableCell>
        <TableCell style={{ minWidth: 300 }}>
          <Typography variant="subtitle1" gutterBottom>
            {camelToText(ref.type || "")}
          </Typography>
          {ref.type === "newEnquiryFollowup" ? (
            <>
              <Typography gutterBottom variant="body2">
                {truncate(ref.message?.content || "", 100)}
              </Typography>
              {ref.message?.type === "spam" && (
                <Typography gutterBottom variant="body2">
                  Marked as spam
                </Typography>
              )}
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={viewMsg}
              >
                View Full Message
              </Button>
            </>
          ) : ref.type === "newBookingMissingDetails" ? (
            <>
              <InfoCol row={ref.booking} />
            </>
          ) : ref.type === "newBookingFollowup" ? (
            <InfoCol row={ref.booking} />
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell style={{ minWidth: 350 }}>
          <Typography variant="subtitle2" gutterBottom>
            {ref.name}
          </Typography>
          {truncate(aiResponse, ref.type === "newBookingFollowup" ? 500 : 100)
            .split("\n")
            .map((text, idx) => (
              <Typography
                key={idx}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></Typography>
            ))}
          <Box mt={1}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={viewResponse}
            >
              View Full Response
            </Button>
          </Box>
        </TableCell>
        <TableCell style={{ minWidth: 350 }}>
          {ref.type === "newBookingFollowup" && (
            <TaskNewBookingOptions
              task={ref}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          )}
        </TableCell>
        <TableCell style={{ width: 200 }}>
          {ref.type === "newEnquiryFollowup" ? (
            <>
              <ButtonGroup
                disabled={loading}
                orientation="vertical"
                color="primary"
                fullWidth
              >
                <Button onClick={() => onOpenChat(false)}>Manual Reply?</Button>
                <Button onClick={() => onOpenChat(true)}>Ai Reply?</Button>
                <Button onClick={setMessageAsSpam}>Mark as spam</Button>
                {/* <Button>Create Booking</Button> */}
              </ButtonGroup>
            </>
          ) : ref.type === "newBookingMissingDetails" ? (
            <>
              <ButtonGroup
                disabled={loading}
                orientation="vertical"
                color="primary"
                fullWidth
              >
                <Button onClick={() => onOpenChat(false)}>Manual Reply?</Button>
                <Button onClick={() => onOpenChat(true)}>Ai Reply?</Button>
              </ButtonGroup>
            </>
          ) : ref.type === "newBookingFollowup" ? (
            <>
              <ButtonGroup
                disabled={loading}
                orientation="vertical"
                color="primary"
                fullWidth
              >
                <Button onClick={createOptions}>
                  {selectedOptions.length > 0
                    ? `Create Options (${selectedOptions.length} boat(s) selected)`
                    : `Create Blank Options`}
                </Button>
                <Button onClick={createQuote}>
                  {selectedOptions.length > 0
                    ? `Create Quote (${selectedOptions.length} boat(s) selected)`
                    : `Create Blank Quote`}
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <></>
          )}
          <Box mt={2}>
            <Button
              onClick={markAsDone}
              color="primary"
              fullWidth
              variant="contained"
            >
              Mark as done
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <Drawer
        anchor="right"
        variant="persistent"
        open={chatOpen}
        PaperProps={{
          style: {
            width: 550,
          },
        }}
      >
        {chatOpen && (
          <Chat
            open={chatOpen}
            messages={messages}
            onSend={onChatSend}
            mode={"lead"}
            onClose={onChatClose}
            message={chatUseAi ? aiResponse : undefined}
            // setMode={setMode}
            // owners={ownersData?.owners || []}
            // owner={msgOwner}
            // setOwner={setMsgOwner}
            sendSms={sendSms}
            setSendSms={setSendSms}
          />
        )}
      </Drawer>
    </>
  );
};

function TaskTable({ tasks, onRefetch }) {
  return (
    <>
      <CardTable
        initialRows={10}
        tableHead={[
          "Info",
          "Status & Request",
          "Ai Response",
          "Options",
          "Actions",
        ]}
        tableData={tasks}
        rowComponent={Row(onRefetch)}
      />
    </>
  );
}

export default TaskTable;
