import { useDrag } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";
import { FeatureIcon, FeatureIconSelector } from "components-library";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    cursor: "move",
    padding: theme.spacing(1, 2),
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  checkControl: {
    width: "100%",
  },
  check: {
    padding: theme.spacing(0.5, 1),
  },
}));

const FeatureDragCard = ({ feature, onDelete, onUpdate, onEdit, checkbox }) => {
  const { card, icon, checkControl, check } = useStyles();
  const [localName, setLocalName] = useState(feature.name);
  const [localIcon, setLocalIcon] = useState(feature.icon);
  const [{ isDragging }, drag] = useDrag({
    item: { type: "FetaureDragCard", feature },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  if (checkbox) {
    return (
      <FormControlLabel
        className={checkControl}
        ref={drag}
        control={<Checkbox color="primary" className={check} />}
        label={feature.name}
      />
    );
  }
  return (
    <ListItem
      ref={drag}
      component={Paper}
      variant="outlined"
      style={{ opacity }}
      className={card}
    >
      <ListItemAvatar>
        <Avatar variant="rounded" className={icon}>
          <DragIndicatorIcon />
        </Avatar>
      </ListItemAvatar>
      {typeof onUpdate === "function" ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Feature Name"
              value={localName || ""}
              variant="outlined"
              onChange={(e) => setLocalName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ maxWidth: 220 }}>
            <FeatureIconSelector
              selectedIcon={localIcon}
              setSelectedIcon={setLocalIcon}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <FeatureIcon
            icon={feature.icon}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          <ListItemText
            primary={feature.name}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </>
      )}

      <ListItemSecondaryAction>
        {typeof onEdit === "function" ? (
          <>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                onEdit(feature);
              }}
            >
              <Edit />
            </IconButton>
          </>
        ) : null}
        {typeof onUpdate === "function" ? (
          <>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                onUpdate({ ...feature, name: localName, icon: localIcon });
              }}
            >
              <SaveIcon />
            </IconButton>
          </>
        ) : null}
        {typeof onDelete === "function" ? (
          <>
            <IconButton edge="end" onClick={() => onDelete(feature)}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FeatureDragCard;
