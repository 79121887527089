import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BOAT,
  CREATE_BOAT_LAYOUT,
  CREATE_BOAT_PACKAGE,
  CREATE_BOAT_PRICE,
  CREATE_BOAT_PRICE_ITEM,
  CREATE_BOAT_PRICE_SPECIAL,
  CREATE_BOAT_TYPE,
  CREATE_OWNER,
  CREATE_STRIPE_ACCOUNT,
  CREATE_STRIPE_LINK,
  DELETE_BOAT,
  DELETE_BOAT_LAYOUT,
  DELETE_BOAT_PACKAGE,
  DELETE_BOAT_PRICE,
  DELETE_BOAT_PRICE_SPECIAL,
  DELETE_BOAT_TYPE,
  DELETE_OWNER,
  GET_ALL_BOATS,
  GET_ALL_BOAT_LAYOUTS,
  GET_ALL_BOAT_TYPES,
  GET_ALL_FULL_BOATS,
  GET_ALL_OWNERS,
  GET_BOAT,
  GET_BOAT_LAYOUT,
  GET_BOAT_WITH_QUOTE_PRICE,
  GET_OWNER,
  GET_RECOMMENDED_BOATS,
  REMOVE_STRIPE_ACCOUNT,
  UPDATE_BOAT,
  UPDATE_BOAT_LAYOUT,
  UPDATE_BOAT_PACKAGE,
  UPDATE_BOAT_PRICE,
  UPDATE_BOAT_PRICE_ITEM,
  UPDATE_BOAT_PRICE_SPECIAL,
  UPDATE_BOAT_TYPE,
  UPDATE_OWNER,
} from "@operations/boatOperations";

/* -------------------------------------------------------------------------- */
/*                                BOAT ACTIONS                                */
/* -------------------------------------------------------------------------- */

export const useGetBoat = (id) => useQuery(GET_BOAT, { variables: { id } });
export const useGetBoatWithQuotePrice = (id) =>
  useQuery(GET_BOAT_WITH_QUOTE_PRICE, { variables: { id } });

export const useGetAllBoats = () => useQuery(GET_ALL_BOATS);
export const useGetAllFullBoats = () => useQuery(GET_ALL_FULL_BOATS);
export const useLazyGetAllFullBoats = () => useLazyQuery(GET_ALL_FULL_BOATS);
export const useGetRecommendedBoats = (bookingID) =>
  useQuery(GET_RECOMMENDED_BOATS, { variables: { bookingID } });

export const useLazyGetRecommendedBoats = () =>
  useLazyQuery(GET_RECOMMENDED_BOATS);

export const useCreateBoat = () =>
  useMutation(CREATE_BOAT, {
    update: (cache, { data: { createBoat } }) => {
      const data = cache.readQuery({ query: GET_ALL_BOATS });
      cache.writeQuery({
        query: GET_ALL_BOATS,
        data: {
          boats: [...data.boats, createBoat],
        },
      });
    },
  });

export const useUpdateBoat = () => useMutation(UPDATE_BOAT);

export const useDeleteBoat = () =>
  useMutation(DELETE_BOAT, {
    update: (cache, { data: { deleteBoat } }) => {
      const { boats } = cache.readQuery({ query: GET_ALL_BOATS });
      cache.writeQuery({
        query: GET_ALL_BOATS,
        data: { boats: boats.filter((b) => b.id !== deleteBoat.id) },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                                 Boat layouts                               */
/* -------------------------------------------------------------------------- */
export const useGetBoatPageLayout = (id) =>
  useQuery(GET_BOAT_LAYOUT, { variables: { id } });

export const useGetAllBoatPageLayouts = () => useQuery(GET_ALL_BOAT_LAYOUTS);

export const useCreateBoatPageLayout = () =>
  useMutation(CREATE_BOAT_LAYOUT, {
    update: (cache, { data: { createBoatPageLayout } }) => {
      const data = cache.readQuery({ query: GET_ALL_BOAT_LAYOUTS });
      cache.writeQuery({
        query: GET_ALL_BOAT_LAYOUTS,
        data: {
          boatPageLayouts: [...data.boatPageLayouts, createBoatPageLayout],
        },
      });
    },
  });

export const useUpdateBoatPageLayout = () => useMutation(UPDATE_BOAT_LAYOUT);

export const useDeleteBoatPageLayout = () =>
  useMutation(DELETE_BOAT_LAYOUT, {
    update: (cache, { data: { deleteBoatPageLayout } }) => {
      const { boatPageLayouts } = cache.readQuery({
        query: GET_ALL_BOAT_LAYOUTS,
      });
      cache.writeQuery({
        query: GET_ALL_BOAT_LAYOUTS,
        data: {
          boatPageLayouts: boatPageLayouts.filter(
            (o) => o.id !== deleteBoatPageLayout.id
          ),
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                                 Boat Owners                                */
/* -------------------------------------------------------------------------- */

export const useGetOwner = (id) => useQuery(GET_OWNER, { variables: { id } });

export const useGetAllOwners = (pollInterval) =>
  useQuery(GET_ALL_OWNERS, { pollInterval });

export const useCreateOwner = () =>
  useMutation(CREATE_OWNER, {
    update: (cache, { data: { createOwner } }) => {
      const data = cache.readQuery({ query: GET_ALL_OWNERS });
      cache.writeQuery({
        query: GET_ALL_OWNERS,
        data: {
          owners: [...data.owners, createOwner],
        },
      });
    },
  });

export const useUpdateOwner = () => useMutation(UPDATE_OWNER);

export const useDeleteOwner = () =>
  useMutation(DELETE_OWNER, {
    update: (cache, { data: { deleteOwner } }) => {
      const { owners } = cache.readQuery({ query: GET_ALL_OWNERS });
      cache.writeQuery({
        query: GET_ALL_OWNERS,
        data: { owners: owners.filter((o) => o.id !== deleteOwner.id) },
      });
    },
  });
export const useCreateStripeAccount = () =>
  useMutation(CREATE_STRIPE_ACCOUNT, {
    update: (cache, { data: { createOwnerStripeAccount } }) => {
      const { owners } = cache.readQuery({ query: GET_ALL_OWNERS });
      cache.writeQuery({
        query: GET_ALL_OWNERS,
        data: {
          owners: owners.map((o) => {
            if (o.id !== createOwnerStripeAccount.owner.id) {
              return o;
            }

            return {
              ...o,
              stripeAccounts: [
                ...(o.stripeAccounts || []),
                createOwnerStripeAccount,
              ],
            };
          }),
        },
      });
    },
  });

export const useRemoveStripeAccount = () =>
  useMutation(REMOVE_STRIPE_ACCOUNT, {
    update: (cache, { data: { removeOwnerStripeAccount } }) => {
      const { owners } = cache.readQuery({ query: GET_ALL_OWNERS });
      cache.writeQuery({
        query: GET_ALL_OWNERS,
        data: {
          owners: owners.map((o) => {
            if (o.id !== removeOwnerStripeAccount.id) {
              return o;
            }

            return {
              ...removeOwnerStripeAccount,
            };
          }),
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                            BOAT PACKAGE ACTIONS                            */
/* -------------------------------------------------------------------------- */

export const useCreateBoatPackage = (boatId) =>
  useMutation(CREATE_BOAT_PACKAGE, {
    update: (cache, { data: { createBoatPackage } }) => {
      if (boatId) {
        const { boat } = cache.readQuery({
          query: GET_BOAT,
          variables: { id: boatId },
        });
        updateBoatPackageCache(cache, boat, [
          ...boat.boatPackages,
          createBoatPackage,
        ]);
      }
    },
  });

export const useUpdateBoatPackage = () => useMutation(UPDATE_BOAT_PACKAGE);

export const useDeleteBoatPackage = (boatId) =>
  useMutation(DELETE_BOAT_PACKAGE, {
    update: (cache, { data: { deleteBoatPackage } }) => {
      if (boatId) {
        const { boat } = cache.readQuery({
          query: GET_BOAT,
          variables: { id: boatId },
        });
        updateBoatPackageCache(cache, boat, [
          ...boat.boatPackages.filter((p) => p.id !== deleteBoatPackage.id),
        ]);
      }
    },
  });

/* -------------------------------------------------------------------------- */
/*                                 BOAT PRICES                                */
/* -------------------------------------------------------------------------- */

export const useCreateBoatPrice = (boatId) =>
  useMutation(CREATE_BOAT_PRICE, {
    update: (cache, { data: { createBoatPrice } }) => {
      if (!boatId) {
        return;
      }
      // add boat price to boat inline
      const { boat } = cache.readQuery({
        query: GET_BOAT,
        variables: { id: boatId },
      });
      cache.writeQuery({
        query: GET_BOAT,
        variables: { id: boat.id },
        data: {
          boat: {
            ...boat,
            boatPrices: [...boat.boatPrices, createBoatPrice],
          },
        },
      });
    },
  });

export const useUpdateBoatPrice = () => useMutation(UPDATE_BOAT_PRICE);

export const useDeleteBoatPrice = (boatId) =>
  useMutation(DELETE_BOAT_PRICE, {
    update: (cache, { data: { deleteBoatPrice } }) => {
      if (!boatId) {
        return;
      }
      const { boat } = cache.readQuery({
        query: GET_BOAT,
        variables: { id: boatId },
      });

      const boatPriceSpecials = boat.boatPriceSpecials.map((bps) => {
        if (!bps.boatPrice) {
          return bps;
        }
        if (bps.boatPrice.id === deleteBoatPrice.id) {
          return { ...bps, boatPrice: null };
        }
        return bps;
      });
      const newBoat = { ...boat };
      if (newBoat.boatPrice && newBoat.boatPrice.id === deleteBoatPrice.id) {
        newBoat.boatPriceId = null;
        newBoat.boatPrice = null;
      }
      updatePricesCache(
        cache,
        newBoat,
        newBoat.boatPrices.filter((bp) => bp.id !== deleteBoatPrice.id),
        boatPriceSpecials
      );
    },
  });

export const useCreateBoatPriceItem = () => useMutation(CREATE_BOAT_PRICE_ITEM);

export const useUpdateBoatPriceItem = () => useMutation(UPDATE_BOAT_PRICE_ITEM);

/* -------------------------------------------------------------------------- */
/*                             BOAT PRICE SPECIAL                             */
/* -------------------------------------------------------------------------- */

export const useCreateBoatPriceSpecial = (boatId) =>
  useMutation(CREATE_BOAT_PRICE_SPECIAL, {
    update: (cache, { data: { createBoatPriceSpecial } }) => {
      if (!boatId) {
        return;
      }
      updateBoatSpecialCache(cache, boatId, createBoatPriceSpecial);
    },
  });

export const useUpdateBoatPriceSpecial = (boatId) =>
  useMutation(UPDATE_BOAT_PRICE_SPECIAL, {
    update: (cache, { data: { updateBoatPriceSpecial } }) => {
      if (!boatId) {
        return;
      }
      updateBoatSpecialCache(cache, boatId, updateBoatPriceSpecial);
    },
  });

export const useDeleteBoatPriceSpecial = (boatId) =>
  useMutation(DELETE_BOAT_PRICE_SPECIAL, {
    update: (cache, { data: { deleteBoatPriceSpecial } }) => {
      if (!boatId) {
        return;
      }
      const { boat } = cache.readQuery({
        query: GET_BOAT,
        variables: { id: boatId },
      });

      updatePricesCache(
        cache,
        boat,
        boat.boatPrices,
        boat.boatPriceSpecials.filter(
          (bps) => bps.id !== deleteBoatPriceSpecial.id
        )
      );
    },
  });

/* -------------------------------------------------------------------------- */
/*                                  BOAT TYPE                                 */
/* -------------------------------------------------------------------------- */

export const useGetAllBoatTypes = () => useQuery(GET_ALL_BOAT_TYPES);

export const useCreateBoatType = () =>
  useMutation(CREATE_BOAT_TYPE, {
    update: (cache, { data: { createBoatType } }) => {
      updateBoatTypeCache(cache, createBoatType);
    },
  });

export const useUpdateBoatType = () => useMutation(UPDATE_BOAT_TYPE);

export const useDeleteBoatType = () =>
  useMutation(DELETE_BOAT_TYPE, {
    update: (cache, { data: { deleteBoatType } }) => {
      updateBoatTypeCache(cache, deleteBoatType, true);
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const updateBoatPackageCache = (cache, boat, boatPackages) => {
  cache.writeQuery({
    query: GET_BOAT,
    variables: { id: boat.id },
    data: {
      boat: {
        ...boat,
        boatPackages,
      },
    },
  });
};

const updatePricesCache = (cache, boat, boatPrices, boatPriceSpecials) => {
  cache.writeQuery({
    query: GET_BOAT,
    variables: { id: boat.id },
    data: {
      boat: {
        ...boat,
        boatPrices,
        boatPriceSpecials,
      },
    },
  });
};

const updateBoatSpecialCache = (cache, boatId, special) => {
  const { boat } = cache.readQuery({
    query: GET_BOAT,
    variables: { id: boatId },
  });
  cache.writeQuery({
    query: GET_BOAT,
    variables: { id: boat.id },
    data: {
      boat: {
        ...boat,
        boatPriceSpecials: [...boat.boatPriceSpecials, special],
      },
    },
  });
};

const updateBoatTypeCache = (cache, boatType, remove) => {
  const { boatTypes } = cache.readQuery({
    query: GET_ALL_BOAT_TYPES,
  });
  cache.writeQuery({
    query: GET_ALL_BOAT_TYPES,
    data: {
      boatTypes: remove
        ? boatTypes.filter((bt) => bt.id !== boatType.id)
        : [...boatTypes, boatType],
    },
  });
};
