import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_TASKS, MARK_AS_DONE } from "@store/operations/taskOperations";

export const useGetAllTasks = (filter) =>
  useQuery(GET_ALL_TASKS, {
    variables: {
      filter,
    },
  });

export const useMarkAsDone = (id) =>
  useMutation(MARK_AS_DONE, {
    variables: {
      id,
    },
  });
