import { gql } from "@apollo/client";

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages($filter: String) {
    messages(filter: $filter) {
      id
      content
      toAddress
      createdAt
      status
      type
      automationStatus
      automationRetry
      lead {
        id
        email
        firstName
        lastName
        bookings {
          id
          reference
          cruiseDate
        }
      }
    }
  }
`;
export const GET_ALL_MESSAGES_BY_LEAD = gql`
  query GetAllMessagesByLead($leadID: UUID!) {
    messagesByLead(leadID: $leadID) {
      id
      content
      toAddress
      createdAt
      status
      type
      automationStatus
      automationRetry
      lead {
        id
        email
        firstName
        lastName
      }
      toLead {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_MESSAGE = gql`
  query Message($id: UUID!) {
    message(id: $id) {
      id
      content
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: MessageInput!) {
    createMessage(input: $input) {
      id
      content
    }
  }
`;
export const SEND_LEAD_MESSAGE = gql`
  mutation SendLeadMessage($input: MessageInput!, $toLeadID: UUID!) {
    sendAndCreateLeadMessage(input: $input, toLeadID: $toLeadID) {
      id
      content
      toAddress
      createdAt
      seenOn
      status
      type
      automationStatus
      automationRetry
      lead {
        id
        email
        firstName
        lastName
      }
      quote {
        id
        reference
      }
      toLead {
        id
        email
        firstName
        lastName
      }
      booking {
        id
        reference
      }
      bookingOption {
        id
        reference
      }
    }
  }
`;
export const SEND_OWNER_MESSAGE = gql`
  mutation SendOwnerMessage($input: MessageInput!, $toOwnerID: UUID!) {
    sendAndCreateOwnerMessage(input: $input, toOwnerID: $toOwnerID) {
      id
      content
      createdAt
      seenOn
      status
      type
      automationStatus
      automationRetry
      owner {
        firstName
      }
      toOwner {
        firstName
      }
      quote {
        id
        reference
      }
      booking {
        id
        reference
      }
      bookingOption {
        id
        reference
      }
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($id: UUID!, $input: MessageInput!) {
    updateMessage(id: $id, input: $input) {
      id
      content
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($id: UUID!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;

export const MARK_AS_SEEN = gql`
  mutation MarkAsSeen($id: UUID!) {
    markAsSeen(id: $id) {
      id
      content
      createdAt
      seenOn
      type
      automationStatus
      automationRetry
      lead {
        firstName
      }
      quote {
        id
        reference
      }
      booking {
        id
        reference
      }
      bookingOption {
        id
        reference
      }
    }
  }
`;
