import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useCreateFeature, useUpdateFeature } from "@actions/featureActions";
import React, { useCallback, useEffect, useState } from "react";
import { FeatureIconSelector } from "components-library";

const NewFeatureModal = ({ feature, open, setOpen, onSave }) => {
  const [name, setName] = useState(feature?.name || "");
  const [icon, setIcon] = useState(feature?.icon || "");
  const [addFeature] = useCreateFeature();
  const [updateFeature] = useUpdateFeature();

  useEffect(() => {
    return () => {
      if (open) {
        setName("");
        setIcon("");
      }
    };
  }, [open]);

  const handleClose = () => {
    setName("");
    setIcon("");
    setOpen(false);
  };

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      if (feature && feature.id) {
        updateFeature({
          variables: {
            id: feature.id,
            input: { name, icon },
          },
        }).then((r) => {
          onSave(r.data.updateFeature);
        });
      } else {
        addFeature({
          variables: {
            input: { name, icon },
          },
        }).then((r) => {
          onSave(r.data.createFeature);
        });
      }
    },
    [name, icon]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Feature</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the features name.</DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Feature Name"
                fullWidth
                value={name}
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FeatureIconSelector
                selectedIcon={icon}
                setSelectedIcon={setIcon}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewFeatureModal;
