import BookingFormContent from "@components/booking/BookingFormContent";
import BookingItemModal from "@components/booking/BookingItemModal";
import BookingItemsList from "@components/booking/BookingItemsList";
import MessageModal from "@components/message/MessageModal";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useCheckAvailability,
  useDeleteBookingItem,
  useSendAvailabilityCheck,
} from "@store/actions/bookingActions";
import moment from "moment";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

function BookingGeneral() {
  const {
    booking,
    setBooking,
    saveBooking,
    setBookingItem,
  } = useBookingContext();
  const [msgOpen, setMsgOpen] = useState(false);
  const [noCalander, setNoCalander] = useState(false);
  const [availableCheckResp, setAvailableCheckResp] = useState([]);
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [open, setOpen] = useState(false);
  const [deleteBookingItem, { loading: deletingItem }] = useDeleteBookingItem(
    booking
  );
  const [sendAvailabilityCheck] = useSendAvailabilityCheck();
  const [checkAvailability] = useCheckAvailability();
  const save = (e) => {
    e.preventDefault();
    saveBooking().then((res) =>
      showNotification(`${res.data.updateBooking.reference} Updated`)
    );
  };

  const checkAvailabilityFn = async () => {
    const res = await checkAvailability({
      variables: {
        id: booking.id,
      },
    });

    if (!res.data?.checkAvailability) {
      setNoCalander(true);
    }

    setAvailableCheckResp(res.data?.checkAvailability);
  };

  const checkAvailabilityOpen = () => {
    setMsgOpen(true);
  };

  const newItem = () => {
    setBookingItem({});
    setOpen(true);
  };
  const onEditItem = (item) => {
    setBookingItem(item);
    setOpen(true);
  };
  const onDeleteItem = ({ id }) => {
    openConfirmation({
      title: `Delete this booking item`,
      body: `Deleting this booking item can not be undone, are you sure you want to continue?`,
      loading: () => deletingItem,
      callback: () =>
        deleteBookingItem({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`Booking item Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onSendAvailabilityCheck = async (message) => {
    try {
      await sendAvailabilityCheck({
        variables: {
          id: booking.id,
          message,
        },
      });

      showNotification(`${booking.reference} availability check sent!`);
    } catch (error) {
      console.error(error, "log error");
    }
    setMsgOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom>
            Manage Booking Ref : {booking.reference}
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button variant="contained" onClick={checkAvailabilityFn}>
              Check Availability
            </Button>
            <Box ml={2}>
              {noCalander ? (
                <Typography>Booking Items have no calanders</Typography>
              ) : (
                availableCheckResp?.map((r) => (
                  <Box key={r.boatName}>
                    <Typography variant="body2"> {r.boatName}</Typography>
                    <Typography variant="body2">
                      {!r.hasCalendar
                        ? "No Calander"
                        : isAvailableCalendar
                        ? "Calendar checked, available"
                        : "Calendar checked, not available"}
                    </Typography>
                    <Typography variant="body2">
                      {isAvailableBookings
                        ? "No Booking Conflicts"
                        : "Booking Conflicts Found"}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>

          {!booking.availabilityCheckSentOn ? (
            <Button variant="contained" onClick={checkAvailabilityOpen}>
              Send Availabilty Check
            </Button>
          ) : (
            <Typography variant="caption">
              Availability Check sent on:{" "}
              {moment(booking.availabilityCheckSentOn).format("ll")}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <ValidatorForm onSubmit={save}>
              <Card>
                <CardHeader title="Booking details" />
                <Divider />
                <CardContent>
                  <BookingFormContent
                    booking={booking}
                    setBooking={setBooking}
                    onEditItem={onEditItem}
                  />
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button variant="contained" color="primary" type="submit">
                      Update Booking
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </ValidatorForm>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom>
              Booking items
            </Typography>
            <Typography variant="body2">
              Booking items are the boats that this booking includes. If you
              want to add a boat to a quote or option it must be added as a
              booking item.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={newItem}
            >
              New Booking Item
            </Button>
          </Grid>
          <Grid item xs={12}>
            <BookingItemsList
              booking={booking}
              bookingItems={booking.items || []}
              onEdit={onEditItem}
              onDelete={onDeleteItem}
            />
          </Grid>
        </Grid>
      </Grid>

      <BookingItemModal open={open} setOpen={setOpen} />
      <MessageModal
        open={msgOpen}
        setOpen={setMsgOpen}
        onSave={onSendAvailabilityCheck}
      />
    </Grid>
  );
}

export default BookingGeneral;
