import { gql, makeVar } from "@apollo/client";
import DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import Home from "@material-ui/icons/Home";
import PagesIcon from "@material-ui/icons/Pages";
import ImportantDevices from "@material-ui/icons/ImportantDevices";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import GroupIcon from "@material-ui/icons/Group";
import StreetviewIcon from "@material-ui/icons/Streetview";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BoatsPage from "pages/boats/BoatsPage";
import FeaturesPage from "pages/features/FeaturesPage";
import HomePage from "pages/Home";
import LandingPagesPage from "pages/landing/LandingPagesPage";
import WharfsPage from "pages/wharfs/WharfsPage";
import LeadsPage from "pages/leads/LeadsPage";
import BookingsPage from "pages/bookings/BookingsPage";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationsPage from "pages/locations/LocationsPage";
import RowingIcon from "@material-ui/icons/Rowing";
import BoatTypesPage from "pages/boats/BoatTypesPage";
import MediaPage from "pages/media/MediaPage";
import EnquiriesPage from "pages/enquires/EnquiriesPage";
import OwnersPage from "pages/owners/OwnersPage";
import OwnerPage from "pages/owners/OwnerPage";
import SiteMeta from "pages/SiteMeta/SiteMeta";
import WebIcon from "@material-ui/icons/Web";
import ContentBlocksPage from "pages/content-blocks/ContentBlocksPage";
import BoatLayoutsPage from "pages/boat-layouts/BoatLayoutsPage";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PayoutsPage from "pages/payouts/PayoutsPage";
import ContenPagesPage from "pages/contentPage/ContenPagesPage";
import { Computer, EmojiPeople, StarHalf } from "@material-ui/icons";
import StaffListPage from "pages/staff/StaffListPage";
import QuotePage from "pages/quotes/QuotePage";
import UsersPage from "pages/users";
import UserPage from "pages/users/UserPage";
import PackagePagesPage from "pages/package/PackagePagesPage";
import RatingsPage from "pages/ratings/RatingsPage";
import TasksPage from "pages/automation/TasksPage";

export default makeVar([
  {
    path: "/home-page",
    name: "Home Page",
    icon: Home,
    component: HomePage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/site-meta",
    name: "Site Meta",
    icon: ContactPhoneIcon,
    component: SiteMeta,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/boats",
    name: "Boats",
    icon: DirectionsBoat,
    component: BoatsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/boats/:boatid",
    name: "Single boat",
    icon: DirectionsBoat,
    component: BoatsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    group: "admin",
    fragment: {
      field: "name",
      model: "Boat",
      on: gql`
        fragment B on Boat {
          name
        }
      `,
    },
  },
  {
    path: "/boats/:boatid/edit",
    name: "Edit",
    icon: DirectionsBoat,
    component: BoatsPage,
    layout: "/admin",
    group: "admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/owners",
    name: "Boat Owners",
    icon: ChildCareIcon,
    component: OwnersPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/staff",
    name: "Boat Staff",
    icon: EmojiPeople,
    component: StaffListPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/boat-layouts",
    name: "Boat Layouts",
    icon: VerticalSplitIcon,
    component: BoatLayoutsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "admin",
  },
  {
    path: "/owners/:ownerid",
    name: "Boat Owners",
    icon: ChildCareIcon,
    component: OwnerPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
    group: "admin",
  },
  {
    path: "/features",
    name: "Features",
    icon: OutdoorGrillIcon,
    component: FeaturesPage,
    layout: "/admin",
    group: "admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/landing-pages",
    name: "Landing Pages",
    icon: PagesIcon,
    component: LandingPagesPage,
    layout: "/admin",
    group: "admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/landing-pages/:landingPageId",
    name: "Landing Page",
    group: "admin",
    icon: null,
    component: LandingPagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/landing-pages/:landingPageId/edit",
    name: "Landing Page",
    group: "admin",
    icon: null,
    component: LandingPagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/package-pages",
    name: "Package Pages",
    icon: ImportantDevices,
    component: PackagePagesPage,
    layout: "/admin",
    group: "admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/package-pages/:packagePageId",
    name: "Package Page",
    group: "admin",
    icon: null,
    component: PackagePagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/package-pages/:packagePageId/edit",
    name: "Package Page",
    group: "admin",
    icon: null,
    component: PackagePagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/content-pages",
    name: "Content Pages",
    icon: MenuBookIcon,
    component: ContenPagesPage,
    layout: "/admin",
    group: "admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/content-pages/:contentPageId",
    name: "Content Page",
    group: "admin",
    icon: null,
    component: ContenPagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/content-pages/:contentPageId/edit",
    name: "Content Page",
    group: "admin",
    icon: null,
    component: ContenPagesPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/wharfs",
    name: "Wharfs",
    group: "admin",
    icon: StreetviewIcon,
    component: WharfsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/locations",
    name: "Locations",
    group: "admin",
    icon: LocationOnIcon,
    component: LocationsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/content-blocks",
    name: "Content Blocks",
    group: "admin",
    icon: WebIcon,
    component: ContentBlocksPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/boat-types",
    name: "Boat Types",
    group: "admin",
    icon: RowingIcon,
    component: BoatTypesPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/media",
    name: "Media",
    group: "admin",
    icon: PermMediaIcon,
    component: MediaPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/bookings",
    name: "Bookings",
    icon: ConfirmationNumberIcon,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "sales",
  },
  {
    path: "/bookings/:bookingId",
    name: "Bookings",
    group: "sales",
    icon: null,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: {
      field: "reference",
      model: "Booking",
      on: gql`
        fragment B on Booking {
          reference
        }
      `,
    },
  },
  {
    path: "/bookings/:bookingId/options",
    name: "Booking Options",
    group: "sales",
    icon: null,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/bookings/:bookingId/options/:bookingOptionId",
    name: "Booking Options",
    group: "sales",
    icon: null,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: {
      field: "reference",
      model: "BookingOption",
      on: gql`
        fragment Bo on BookingOption {
          reference
        }
      `,
    },
  },
  {
    path: "/bookings/:bookingId/quotes",
    name: "Quotes",
    group: "sales",
    icon: null,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/bookings/:bookingId/quotes/:quoteId",
    name: "Quotes",
    group: "sales",
    icon: null,
    component: BookingsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: {
      field: "reference",
      model: "Quote",
      on: gql`
        fragment q on Quote {
          reference
        }
      `,
    },
  },
  {
    path: "/quotes/:quoteRef",
    name: "Quote By Ref",
    group: "sales",
    component: QuotePage,
    icon: null,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/leads",
    name: "Leads",
    icon: GroupIcon,
    component: LeadsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
    group: "sales",
  },
  {
    path: "/leads/:leadId",
    name: "Leads",
    group: "sales",
    icon: null,
    component: LeadsPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
  {
    path: "/automation",
    name: "Automation",
    group: "sales",
    icon: Computer,
    component: TasksPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/enquiries",
    name: "Enquries",
    group: "sales",
    icon: AlternateEmailIcon,
    component: EnquiriesPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/payouts",
    name: "Payouts",
    group: "sales",
    icon: LocalAtmIcon,
    component: PayoutsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/ratings",
    name: "Pending Ratings",
    group: "sales",
    icon: StarHalf,
    component: RatingsPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/users",
    name: "Users",
    group: "admin",
    icon: PersonAddIcon,
    component: UsersPage,
    layout: "/admin",
    exact: true,
    showMenu: true,
    fragment: null,
  },
  {
    path: "/users/:userId",
    name: "User Stats",
    group: "admin",
    icon: null,
    component: UserPage,
    layout: "/admin",
    exact: true,
    showMenu: false,
    fragment: null,
  },
]);
