import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_LEAD,
  DELETE_LEAD,
  GET_ALL_LEADS,
  GET_LEAD,
  UPDATE_LEAD,
} from "@operations/leadOpertations";

/* -------------------------------------------------------------------------- */
/*                                    LEAD                                    */
/* -------------------------------------------------------------------------- */

export const useGetLead = (id) => useQuery(GET_LEAD, { variables: { id } });

export const useGetAllLeads = () => useQuery(GET_ALL_LEADS);

export const useCreateLead = () =>
  useMutation(CREATE_LEAD, {
    update: (cache, { data: { createLead } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LEADS,
      });
      //  as the lead sometimes updates if same email then we need to check for that
      const found = (data.leads || []).find((l) => l.id === createLead.id);
      if (found) {
        return;
      }
      cache.writeQuery({
        query: GET_ALL_LEADS,
        data: {
          leads: [...(data.leads || []), createLead],
        },
      });
    },
  });

export const useUpdateLead = () => useMutation(UPDATE_LEAD);

export const useDeleteLead = () =>
  useMutation(DELETE_LEAD, {
    update: (cache, { data: { deleteLead } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LEADS,
      });
      const leads = data ? data.leads : [];
      cache.writeQuery({
        query: GET_ALL_LEADS,
        data: {
          leads: leads.filter((w) => w.id !== deleteLead.id),
        },
      });
    },
  });
