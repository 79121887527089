import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
  query GetAllTasks($filter: TasksFilter) {
    task(filter: $filter) {
      id
      name
      description
      meta
      lead {
        id
        email
        firstName
        lastName
        phone
      }
      booking {
        id
        reference
        groupSize
        hours
        cruiseDate
        available
        notes
        availabilityCheckSentOn
        reviewRequestSentOn
        incendiumKey
        catering
        event
        eventOther
        source
        lastStatus
        lastStatusSetOn
        messages {
          seenOn
          lead {
            id
          }
          owner {
            id
          }
        }
        items {
          id
          quoteItems {
            id
          }
          skippers {
            staff {
              id
              firstName
              lastName
              role
            }
          }
          deckies {
            staff {
              id
              firstName
              lastName
              role
            }
          }
          boat {
            id
            name
            skipperRequired
          }
          boatPackage {
            id
            name
          }
          baseCost
          commision
        }
      }
      message {
        id
        content
        type
      }
      type
      createdAt
    }
  }
`;

export const MARK_AS_DONE = gql`
  mutation MarkAsDone($id: UUID!) {
    markAsDone(id: $id) {
      id
    }
  }
`;
