/* -------------------------------------------------------------------------- */
/*                                   quotes                                   */
/* -------------------------------------------------------------------------- */

import { gql } from "@apollo/client";

export const CREATE_QUOTE = gql`
  mutation CreateQuote($bookingID: UUID!, $type: QuoteType!) {
    createQuote(bookingID: $bookingID, type: $type) {
      id
      reference
      type
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation DeleteQuote($id: UUID!) {
    deleteQuote(id: $id) {
      id
    }
  }
`;
export const UPDATE_QUOTE = gql`
  mutation UpdateQuote($id: UUID!, $input: QuoteInput!) {
    updateQuote(id: $id, input: $input) {
      id
      type
      reference
      sentOn
      seenOn
      expired
      waiver {
        id
        name
      }
      waiverSignOn
      items {
        id
        baseCost
        commision
        bookingItem {
          id
          boat {
            id
            name
            oneLiner
            capacity
            minimumHire
            showContact
            waivers {
              id
              name
            }
            features {
              id
              name
            }
            boatTypes {
              id
              name
              path
            }
            medias {
              id
              name
              mediaFiles {
                name
                path
                type
              }
            }
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPrices {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPriceSpecials {
              id
              name
              to
              from
              recurring
              boatPrice {
                id
                name
                baseHourlyRate
                description
                items {
                  id
                  from
                  to
                  rate
                  unit
                  days
                  fixed
                }
              }
            }
            wharfs {
              id
              name
            }
          }
          boatPackage {
            id
            name
          }
        }
        costs {
          id
          description
          cost
          commision
          createdAt
          dropOffWharf {
            id
            name
          }
          pickUpWharf {
            id
            name
          }
          type
        }
      }
    }
  }
`;

export const GET_QUOTE = gql`
  query GetQuote($id: UUID!) {
    quote(id: $id) {
      id
      type
      reference
      sentOn
      seenOn
      expired
      transactions {
        total
      }
      waiver {
        id
        name
      }
      waiverSignOn
      items {
        id
        baseCost
        commision
        createdAt
        bookingItem {
          id
          boat {
            id
            name
            oneLiner
            capacity
            minimumHire
            showContact
            waivers {
              id
              name
            }
            owner {
              id
              firstName
              email
            }
            boatPageLayout {
              id
              name
            }
            features {
              id
              name
            }
            boatTypes {
              id
              name
              path
            }
            medias {
              id
              name
              mediaFiles {
                name
                path
                type
              }
            }
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPrices {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPriceSpecials {
              id
              name
              to
              from
              recurring
              boatPrice {
                id
                name
                baseHourlyRate
                description
                items {
                  id
                  from
                  to
                  rate
                  unit
                  days
                  fixed
                }
              }
            }
            wharfs {
              id
              name
            }
            boatPackages {
              id
              name
            }
          }
          boatPackage {
            id
            name
          }
        }
        costs {
          id
          description
          cost
          commision
          createdAt
          dropOffWharf {
            id
            name
          }
          pickUpWharf {
            id
            name
          }
          package {
            id
            name
          }
          type
        }
      }
    }
  }
`;

export const GET_QUOTE_BY_REF = gql`
  query GetQuote($ref: String!) {
    quoteByRef(ref: $ref) {
      id
      type
      reference
      booking {
        id
      }
    }
  }
`;

export const CREATE_QUOTE_ITEM = gql`
  mutation CreateQuoteItem($quoteID: UUID!, $input: QuoteItemInput!) {
    createQuoteItem(quoteID: $quoteID, input: $input) {
      id
      baseCost
      commision
      createdAt
      bookingItem {
        id
        boat {
          id
          name
          oneLiner
          capacity
          minimumHire
          showContact
          features {
            id
            name
          }
          boatTypes {
            id
            name
            path
          }
          medias {
            id
            name
            mediaFiles {
              name
              path
              type
            }
          }
          boatPrice {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPrices {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPriceSpecials {
            id
            name
            to
            from
            recurring
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
          }
          wharfs {
            id
            name
          }
        }
        boatPackage {
          id
          name
        }
      }
      costs {
        id
        description
        cost
        commision
        createdAt
        dropOffWharf {
          id
          name
        }
        pickUpWharf {
          id
          name
        }
        type
      }
    }
  }
`;
export const UPDATE_QUOTE_ITEM = gql`
  mutation UpdateQuoteItem($id: UUID!, $input: QuoteItemInput!) {
    updateQuoteItem(id: $id, input: $input) {
      id
      baseCost
      commision
      createdAt
      bookingItem {
        id
        boat {
          id
          name
          oneLiner
          capacity
          minimumHire
          showContact
          features {
            id
            name
          }
          boatTypes {
            id
            name
            path
          }
          medias {
            id
            name
            mediaFiles {
              name
              path
              type
            }
          }
          boatPrice {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPrices {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPriceSpecials {
            id
            name
            to
            from
            recurring
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
          }
          wharfs {
            id
            name
          }
        }
        boatPackage {
          id
          name
        }
      }
      costs {
        id
        description
        cost
        commision
        createdAt
        dropOffWharf {
          id
          name
        }
        pickUpWharf {
          id
          name
        }
        type
      }
    }
  }
`;

export const DELETE_QUOTE_ITEM = gql`
  mutation DeleteQuoreItem($id: UUID!) {
    deleteQuoteItem(id: $id) {
      id
    }
  }
`;

export const GET_QUOTE_ESTIMATE = gql`
  query QuoteEstimate(
    $boatId: UUID!
    $cruiseDate: Time!
    $duration: Int!
    $groupSize: Int!
  ) {
    quoteEstimate(
      boatId: $boatId
      cruiseDate: $cruiseDate
      duration: $duration
      groupSize: $groupSize
    ) {
      total
      items {
        total
        description
      }
    }
  }
`;

export const SEND_QUOTE = gql`
  mutation SendQuote($id: UUID!, $message: String!, $sendSms: Boolean) {
    sendQuote(id: $id, message: $message, sendSms: $sendSms) {
      id
      reference
      sentOn
      seenOn
      expired
      items {
        id
        baseCost
        commision
        bookingItem {
          id
          boat {
            id
            name
            oneLiner
            capacity
            minimumHire
            showContact
            features {
              id
              name
            }
            boatTypes {
              id
              name
              path
            }
            medias {
              id
              name
              mediaFiles {
                name
                path
                type
              }
            }
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPrices {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPriceSpecials {
              id
              name
              to
              from
              recurring
              boatPrice {
                id
                name
                baseHourlyRate
                description
                items {
                  id
                  from
                  to
                  rate
                  unit
                  days
                  fixed
                }
              }
            }
            wharfs {
              id
              name
            }
          }
          boatPackage {
            id
            name
          }
        }
        costs {
          id
          description
          cost
          commision
          createdAt
          dropOffWharf {
            id
            name
          }
          pickUpWharf {
            id
            name
          }
          type
        }
      }
    }
  }
`;

export const SEND_QUOTE_HOLD = gql`
  mutation SendOnHold($id: UUID!, $ownerId: UUID!) {
    sendOnHold(id: $id, ownerId: $ownerId) {
      id
    }
  }
`;

export const SEND_QUOTE_RELEASE = gql`
  mutation SendReleaseHold($id: UUID!, $ownerId: UUID!) {
    sendReleaseHold(id: $id, ownerId: $ownerId) {
      id
    }
  }
`;

export const QUOTE_TYPE_ENUM = gql`
  query {
    __type(name: "QuoteType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GET_QUOTE_BREAKDOWN = gql`
  query GetQuotePriceBreakdownByRef($ref: String!) {
    quotePriceBreakdownByRef(ref: $ref) {
      totalWithFees
      paymentFees
      total
      ourCutTotal
      ownerCutTotal
      depositTotal
      depositTotalWithFees
      depositPaymentFees
      depositOurCutTotal
      depositOwnerCutTotal
      canDeposit
      alreadyPaid
      alreadyPaidWithFees
      stripePercentageFee
      stripeFlatFee
      taxMode
      tax
      depositTax
      ownerCutTotalWithTax
      items {
        quoteItemId
        total
        ourCut
        ownerCut
      }
    }
  }
`;

export const GET_QUOTE_USER_STATS = gql`
  query getQuoteStats($userId: UUID!) {
    quoteStats(userId: $userId) {
      sentQuotes
      paidQuotes
      totalRevenue
      averageQuoteRevenue
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                              booking item cost                             */
/* -------------------------------------------------------------------------- */
export const CREATE_BOOKING_ITEM_COST = gql`
  mutation CreateBookingItemCost(
    $quoteItemID: UUID!
    $input: BookingItemCostInput!
  ) {
    createBookingItemCost(quoteItemID: $quoteItemID, input: $input) {
      id
      description
      cost
      commision
      createdAt
      dropOffWharf {
        id
        name
      }
      pickUpWharf {
        id
        name
      }
      type
    }
  }
`;
export const UPDATE_BOOKING_ITEM_COST = gql`
  mutation UpdateBookingItemCost($id: UUID!, $input: BookingItemCostInput!) {
    updateBookingItemCost(id: $id, input: $input) {
      id
      description
      cost
      commision
      createdAt
      dropOffWharf {
        id
        name
      }
      pickUpWharf {
        id
        name
      }
      type
    }
  }
`;

export const DELETE_BOOKING_ITEM_COST = gql`
  mutation DeleteBookingItemCost($id: UUID!) {
    deleteBookingItemCost(id: $id) {
      id
    }
  }
`;

export const GET_ALL_PAYOUTS = gql`
  query GetPayouts {
    payouts {
      id
      createdAt
      amount
      exchangeAmount
      quoteItem {
        bookingItem {
          boat {
            name
          }
        }
      }
      quote {
        reference
        transactions {
          id
        }
        items {
          baseCost
          costs {
            cost
          }
        }
        booking {
          reference
          cruiseDate
          lead {
            email
          }
        }
      }
      owner {
        firstName
        email
        currency
      }
      status
      transferID
      transferGroup
      errorMsg
      attempts
      bookingFullyPaid
      internalTransaction {
        id
        total
        paid
        paymentFees
        ourCut
        ownerCut
      }
    }
  }
`;

export const MARK_PAYOUT_AS_PAID = gql`
  mutation markPayoutAsPaid($id: UUID!) {
    markPayoutAsPaid(id: $id) {
      id
      amount
      exchangeAmount
      owner {
        firstName
        email
      }
      status
      transferID
      transferGroup
      errorMsg
      attempts
      internalTransaction {
        total
        paid
        paymentFees
        ourCut
        ownerCut
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                              transactions                                  */
/* -------------------------------------------------------------------------- */

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction(
    $quoteID: UUID!
    $bookingID: UUID!
    $input: TransactionInput!
    $deposit: Boolean!
    $process: Boolean
  ) {
    createTransaction(
      quoteID: $quoteID
      bookingID: $bookingID
      input: $input
      deposit: $deposit
      process: $process
    ) {
      id
    }
  }
`;
