import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import { useBoatLayoutContents } from "hooks/useBoatLayoutContent";
import { useEffect } from "react";
import { useGetAllfeatureGroups } from "@store/actions/featureActions";
import Loading from "@components/loading/Loading";
import {
  useCreateBoatPageLayout,
  useUpdateBoatPageLayout,
} from "@store/actions/boatActions";
import { useNotificationContext } from "@providers/NotificationProvider";

const BoatLayoutItem = ({
  item,
  setItem,
  addItem,
  removeItem,
  options,
  featureGroups,
}) => {
  const [seletedOption, setSeletedOption] = useState({});
  useEffect(() => {
    const option = options.find((o) => o.value === item.content);
    setSeletedOption(option);
  }, [item]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={item.content === "featureGroup" ? 6 : 10}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label || ""}
          onChange={(_, v) => {
            setItem({
              ...item,
              content: v ? v.value : null,
            });
          }}
          value={seletedOption || {}}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Content"
              size="small"
            />
          )}
        />
      </Grid>
      {item.content === "featureGroup" && (
        <Grid item xs={4}>
          <Autocomplete
            options={featureGroups}
            getOptionLabel={(option) => option.name || ""}
            onChange={(_, v) => {
              setItem({
                ...item,
                featureGroup: v,
              });
            }}
            value={item.featureGroup || {}}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Feature Group"
                size="small"
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={2} container justify="flex-end">
        <IconButton
          variant="contained"
          color="default"
          size="small"
          onClick={removeItem}
        >
          <Remove />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={addItem}
        >
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const BoatLayoutItems = ({ items, setItems }) => {
  const options = useBoatLayoutContents();
  const { loading, data } = useGetAllfeatureGroups();
  const addItem = (i) => (e) => {
    e.stopPropagation();
    const newItems = [...items];
    newItems.splice(i + 1, 0, {});
    setItems(newItems);
  };
  const removeItem = (i) => (e) => {
    e.stopPropagation();
    const newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
  };
  const setItem = (i) => (v) => {
    setItems([
      ...items.map((item, k) => {
        return k === i ? v : item;
      }),
    ]);
  };
  if (loading) return <Loading />;
  return (
    <Grid container spacing={2}>
      {items.length > 0 ? (
        items.map((item, i) => (
          <Grid item xs={12} key={i}>
            <BoatLayoutItem
              options={options}
              item={item}
              setItem={setItem(i)}
              addItem={addItem(i)}
              removeItem={removeItem(i)}
              featureGroups={data.featureGroups || []}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Button onClick={addItem} variant="outlined">
            New Item
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

function BoatLayoutsDialog({ open, setOpen, layout, setLayout }) {
  const { showNotification } = useNotificationContext();
  const [addLayout] = useCreateBoatPageLayout();
  const [updateLayout] = useUpdateBoatPageLayout();

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      name: layout.name,
      items: layout.items
        .filter((item) => item.content)
        .map((item, i) => {
          const iit = {
            order: i + 1,
            content: item.content,
          };
          if (item.featureGroup) {
            iit.featureGroup = { id: item.featureGroup.id };
          }
          return iit;
        }),
    };
    const fn = layout.id ? updateLayout : addLayout;
    const res = await fn({
      variables: {
        id: layout.id || null,
        input,
      },
    });
    showNotification(`${layout.name} Saved!`);
    setLayout((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createBoatPageLayout.id,
    }));
    setOpen(false);
  };

  const close = () => {
    setOpen(false);
  };

  const items = useMemo(
    () =>
      layout.items?.length
        ? layout.items.map((c) => c).sort((a, b) => a.order - b.order)
        : [],
    [layout.items]
  );

  const setItems = (items) => {
    setLayout({
      ...layout,
      items,
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {layout.id ? `Edit ${layout.name}` : `Create Boat Layouts Section`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextValidator
                autoFocus
                variant="outlined"
                name="name"
                label="Name"
                validators={["required"]}
                errorMessages={["Layout requires a Name"]}
                fullWidth
                value={layout.name || ""}
                onChange={(e) => setLayout({ ...layout, name: e.target.value })}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <BoatLayoutItems items={items} setItems={setItems} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default BoatLayoutsDialog;
