import { gql } from "@apollo/client";

export const GET_ALL_FEATURES = gql`
  query GetAllFeatures {
    features {
      id
      name
      icon
      createdAt
      featureGroup {
        name
        id
        id
        live
      }
    }
  }
`;

export const CREATE_FEATURE = gql`
  mutation CreateFeature($input: FeatureInput!) {
    createFeature(input: $input) {
      id
      name
      icon
    }
  }
`;
export const UPDATE_FEATURE = gql`
  mutation UpdateFeature($id: UUID!, $input: FeatureInput!) {
    updateFeature(id: $id, input: $input) {
      id
      name
      icon
    }
  }
`;

export const GET_ALL_FEATURE_GROUPS = gql`
  query GetAllFeatureGroups {
    featureGroups {
      id
      name
      live
      createdAt
      features {
        id
        name
        icon
        createdAt
      }
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation DeleteFeature($id: UUID!) {
    deleteFeature(id: $id) {
      id
    }
  }
`;

export const CREATE_FEATURE_GROUP = gql`
  mutation CreateFeatureGroup($input: FeatureGroupInput!) {
    createFeatureGroup(input: $input) {
      id
      name
      live
      createdAt
    }
  }
`;
export const UPDATE_FEATURE_GROUP = gql`
  mutation UpdateFeatureGroup($id: UUID!, $input: FeatureGroupInput!) {
    updateFeatureGroup(id: $id, input: $input) {
      id
      name
      live
      createdAt
      features {
        id
        name
        icon
        createdAt
      }
    }
  }
`;

export const DELETE_FEATURE_GROUP = gql`
  mutation DeleteFeatureGroup($id: UUID!) {
    deleteFeatureGroup(id: $id) {
      id
    }
  }
`;
