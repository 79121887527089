import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_BOOKING, GET_BOOKING_OPTION } from "@operations/bookingOperations";
import {
  CREATE_MESSAGE,
  DELETE_MESSAGE,
  GET_ALL_MESSAGES,
  GET_ALL_MESSAGES_BY_LEAD,
  GET_MESSAGE,
  MARK_AS_SEEN,
  SEND_LEAD_MESSAGE,
  SEND_OWNER_MESSAGE,
  UPDATE_MESSAGE,
} from "@operations/messageOperations";

/* -------------------------------------------------------------------------- */
/*                                    MESSAGE                                 */
/* -------------------------------------------------------------------------- */

export const useGetMessage = (id) =>
  useQuery(GET_MESSAGE, { variables: { id } });

export const useGetAllMessages = (filter) =>
  useQuery(GET_ALL_MESSAGES, {
    variables: {
      filter,
    },
  });
export const useGetAllMessagesByLad = (leadID) =>
  useQuery(GET_ALL_MESSAGES_BY_LEAD, {
    variables: {
      leadID,
    },
  });
export const useLazyGetAllMessagesByLad = (leadID) =>
  useLazyQuery(GET_ALL_MESSAGES_BY_LEAD, {
    variables: {
      leadID,
    },
  });

export const useCreateMessage = (bookingID, bookingOptionID) =>
  handleSendCreateMsgUpdate(
    CREATE_MESSAGE,
    bookingID,
    bookingOptionID,
    "createMessage"
  );

export const useSendLeadMessage = (bookingID, bookingOptionID) =>
  handleSendCreateMsgUpdate(
    SEND_LEAD_MESSAGE,
    bookingID,
    bookingOptionID,
    "sendAndCreateLeadMessage"
  );
export const useSendOwnerMessage = (bookingID, bookingOptionID) =>
  handleSendCreateMsgUpdate(
    SEND_OWNER_MESSAGE,
    bookingID,
    bookingOptionID,
    "sendAndCreateOwnerMessage"
  );

const handleSendCreateMsgUpdate = (
  action,
  bookingID,
  bookingOptionID,
  resStr
) =>
  useMutation(action, {
    update: (cache, { data: resData }) => {
      const newMessage = resData[resStr];

      let leadId = newMessage.lead?.id || newMessage.toLead?.id;
      if (leadId) {
        const leadData = cache.readQuery({
          query: GET_ALL_MESSAGES_BY_LEAD,
          variables: { leadID: leadId },
        });

        cache.writeQuery({
          query: GET_ALL_MESSAGES_BY_LEAD,
          variables: { leadID: leadId },
          data: {
            messagesByLead: [...(leadData.messagesByLead || []), newMessage],
          },
        });
      }

      if (bookingID) {
        const bookingData = cache.readQuery({
          query: GET_BOOKING,
          variables: { id: bookingID },
        });

        if (bookingData) {
          cache.writeQuery({
            query: GET_BOOKING,
            variables: { id: bookingID },
            data: {
              booking: {
                ...bookingData.booking,
                messages: [...(bookingData.booking.messages || []), newMessage],
              },
            },
          });
        }
      }
      if (bookingOptionID) {
        const data = cache.readQuery({
          query: GET_BOOKING_OPTION,
          variables: { id: bookingOptionID },
        });
        cache.writeQuery({
          query: GET_BOOKING_OPTION,
          variables: { id: bookingOptionID },
          data: {
            bookingOption: {
              ...data.bookingOption,
              messages: [...(data.bookingOption.messages || []), newMessage],
            },
          },
        });
      }
    },
  });

export const useUpdateMessage = () => useMutation(UPDATE_MESSAGE);

export const useDeleteMessage = () =>
  useMutation(DELETE_MESSAGE, {
    update: (cache, { data: { deleteMessage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_MESSAGES,
        variables: {
          filter: JSON.stringify({
            booking_id: null,
          }),
        },
      });
      const messages = data ? data.messages : [];
      cache.writeQuery({
        query: GET_ALL_MESSAGES,
        variables: {
          filter: JSON.stringify({
            booking_id: null,
          }),
        },
        data: {
          messages: messages.filter((w) => w.id !== deleteMessage.id),
        },
      });
    },
  });

export const useMarkAsSeen = () => useMutation(MARK_AS_SEEN);
