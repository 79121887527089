import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@material-ui/core";
import { useLazyGetRecommendedBoats } from "@store/actions/boatActions";
import { GET_BOAT_WITH_QUOTE_PRICE } from "@store/operations/boatOperations";
import { GET_QUOTE_ESTIMATE } from "@store/operations/quoteOperations";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { formatNumber } from "utils/numbers";

function TaskNewBookingOptions({ task, selectedOptions, setSelectedOptions }) {
  const [
    getRecomendations,
    { loading, data, called },
  ] = useLazyGetRecommendedBoats();
  const [metaBoats, setMetaBoats] = useState([]);
  const client = useApolloClient();

  const meta = useMemo(() => {
    return JSON.parse(task.meta);
  }, [task]);

  const preloadMetaBoats = useCallback(
    async (meta) => {
      const arr = await Promise.all(
        meta.map(async (m) => {
          const res = await client.query({
            query: GET_BOAT_WITH_QUOTE_PRICE,
            variables: {
              id: m.boatId,
              booking: {
                cruiseDate: task.booking?.cruiseDate,
                duration: task.booking?.hours,
                groupSize: task.booking?.groupSize,
              },
            },
          });

          return res.data.boatWithQuotePrice;
        })
      );

      setMetaBoats(arr);
    },
    [task.booking]
  );

  useEffect(() => {
    if (!meta || metaBoats.length > 0) {
      return;
    }
    preloadMetaBoats(meta);
  }, [meta]);

  const isAvailable = useMemo(() => {
    let is = true;
    for (let i = 0; i < meta.length; i++) {
      const item = meta[i];
      if (!item.isAvailableCalendar || !item.isAvailableBookings) {
        is = false;
        break;
      }
    }
    return is;
  }, [meta]);

  const recommendedOptions = useMemo(
    () => [...new Set(data?.recommendedBoats || [])],
    [data]
  );

  const handleChange = useCallback(
    (e, v) => {
      let id = e.target.name;
      const found = [...recommendedOptions, ...metaBoats].find(
        (r) => r.id === id
      );
      if (!found) {
        return;
      }
      setSelectedOptions((options) =>
        produce(options, (draft) => {
          const idx = draft.findIndex((d) => d.id === id);
          if (idx >= 0) {
            draft.splice(idx, 1);
          } else {
            draft.push(found);
          }
        })
      );
    },
    [recommendedOptions, metaBoats]
  );

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        {isAvailable
          ? "Boats Available, select to create options or quotes from"
          : "Boats Not Available, select to use in options or quote anyway"}
      </Typography>

      <FormGroup>
        {metaBoats.map((option) => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={selectedOptions.map((s) => s.id).includes(option.id)}
                onChange={handleChange}
                name={option.id}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                size="small"
              />
            }
            label={`${option.name} (${
              option.calculatedQuotePrice
                ? `$${formatNumber(option.calculatedQuotePrice)}`
                : "Unkown"
            })`}
          />
        ))}
      </FormGroup>
      <Box my={2}>
        <Divider />
      </Box>
      {!isAvailable && (
        <>
          <Typography variant="body2" gutterBottom>
            See if there are any other boats that would work available.
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              getRecomendations({ variables: { bookingID: task.booking?.id } })
            }
          >
            Get Options
          </Button>
        </>
      )}
      {loading && (
        <Box mt={2}>
          <CircularProgress />
        </Box>
      )}

      {called && !loading && recommendedOptions.length === 0 && (
        <Box mt={2}>
          <Typography>No Recomended Boats</Typography>
        </Box>
      )}

      {recommendedOptions.length > 0 && (
        <Box mt={2}>
          <FormControl>
            <FormLabel style={{ marginBottom: 10 }}>
              Select From These Boats
            </FormLabel>
            <FormGroup>
              {recommendedOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={selectedOptions
                        .map((s) => s.id)
                        .includes(option.id)}
                      onChange={handleChange}
                      name={option.id}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      size="small"
                    />
                  }
                  label={`${option.name} (${
                    option.calculatedQuotePrice
                      ? `$${formatNumber(option.calculatedQuotePrice)}`
                      : "Unkown"
                  })`}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
    </div>
  );
}

export default TaskNewBookingOptions;
