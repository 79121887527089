import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Loading from "@components/loading/Loading";

const ConfirmationModal = ({
  open,
  title,
  body,
  setOpen,
  onConfirm,
  loading,
  setLoading,
}) => {
  const showLoading = () => {
    return typeof loading === "function" ? loading() : loading;
  };

  const onConfirmAction = (e) => {
    if (typeof loading === "undefined") {
      // bit hacky but we presuke if control not set for loading then calling the action should set it
      setLoading(true);
    }
    onConfirm(e);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      open={open}
    >
      {showLoading() && <Loading size={40} color="primary" overlay={loading} />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {(body || "").split("\n").map((text, idx) => (
          <Typography
            key={idx}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" autoFocus onClick={onConfirmAction}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default ConfirmationModal;
