import DefaultLocationSelector from "@components/defaultLocationSelector/DefaultLocationSelector";
import SearchEditForm from "@components/home/SearchEditForm";
import FooterCreator from "@components/footerCreator/FooterCreator";
import Loading from "@components/loading/Loading";
import BoatSEOTemplate from "@components/siteMeta/BoatSEOTemplate";
import SiteMetaForm from "@components/siteMeta/SiteMetaForm";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllLocations } from "@store/actions/locationActions";
import {
  useGetSiteMeta,
  useUpdateSiteMeta,
} from "@store/actions/siteMetaActions";
import React, { useCallback, useEffect, useState } from "react";
import { TabPanel, useTabbedPageStyles } from "../bookings/BookingPage";
import Contact from "./Contact";
import ManualPayoutSettings from "@components/ManualPayoutSettings/ManualPayoutSettings";
import { useGetAllContentPages } from "@store/actions/landingPageActions";

function SiteMeta() {
  const { loading, data } = useGetSiteMeta();
  const [updateSitemeta] = useUpdateSiteMeta();
  const [tab, setTab] = useState(0);
  const [siteMeta, setSiteMeta] = useState({});
  const { root, tabs } = useTabbedPageStyles();
  const { showNotification } = useNotificationContext();
  const { data: locationsData } = useGetAllLocations();
  const { data: contentPagesData } = useGetAllContentPages();

  useEffect(() => {
    if (data?.siteMeta) {
      setSiteMeta(data.siteMeta);
    }
  }, [data]);

  const save = useCallback(async () => {
    const input = {
      contactBlurb: siteMeta.contactBlurb,
      emailContactBlurb: siteMeta.emailContactBlurb,
      ownerEmailContactBlurb: siteMeta.ownerEmailContactBlurb,
      searchBox: siteMeta.searchBox,
      siteName: siteMeta.siteName,
      googleConsole: siteMeta.googleConsole,
      noIndex: siteMeta.noIndex,
      boatSEOTitleTemplate: siteMeta.boatSEOTitleTemplate,
      boatSEODescriptionTemplate: siteMeta.boatSEODescriptionTemplate,
      defaultLocationID: siteMeta.defaultLocation
        ? siteMeta.defaultLocation.id
        : undefined,
      footerContent: siteMeta.footerContent,
      footerSlateJsons: siteMeta.footerSlateJsons,
      footerIframeLink: siteMeta.footerIframeLink,
      footerIframeTitle: siteMeta.footerIframeTitle,
      manualPayoutManagementEmails: siteMeta.manualPayoutManagementEmails,
      footerTermsAndConditionsLink: siteMeta.footerTermsAndConditionsLink,
      termsContentPageId: siteMeta.termsContentPage
        ? siteMeta.termsContentPage.id
        : undefined,
      policy: siteMeta.policy,
    };
    try {
      await updateSitemeta({
        variables: {
          input,
        },
      });

      showNotification("Site Meta Saved");
    } catch (e) {
      showNotification("Failed to Save Site Meta");
    }
  }, [siteMeta]);

  if (loading || !Object.keys(siteMeta).length) {
    return <Loading />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Site Meta
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <div className={root}>
          <Tabs
            orientation="vertical"
            value={tab}
            onChange={(_, v) => setTab(v)}
            className={tabs}
          >
            <Tab label="Contact" id="contact" />
            <Tab label="Email Contact" id="email-contact" />
            <Tab label="Owner Email Contact" id="owner-email-contact" />
            <Tab label="Search Box" id="searchBox" />
            <Tab label="General Meta" id="meta" />
            <Tab label="Boat SEO template" id="boat-seo-template" />
            <Tab label="Default Location" id="default-location" />
            <Tab label="Custom footer" id="custom-footer" />
            <Tab label="Manual Payout Setting" id="manual-payout-settings" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Grid item xs={8}>
              <Contact
                siteMeta={siteMeta}
                setSiteMeta={setSiteMeta}
                onSave={save}
                field="contactBlurb"
              />
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid item xs={8}>
              <Contact
                siteMeta={siteMeta}
                setSiteMeta={setSiteMeta}
                onSave={save}
                field="emailContactBlurb"
              />
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Grid item xs={8}>
              <Contact
                siteMeta={siteMeta}
                setSiteMeta={setSiteMeta}
                onSave={save}
                field="ownerEmailContactBlurb"
              />
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <SearchEditForm
              gridSize={12}
              onSave={save}
              siteMeta={siteMeta}
              setSiteMeta={setSiteMeta}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <SiteMetaForm
              onSave={save}
              siteMeta={siteMeta}
              setSiteMeta={setSiteMeta}
              loading={loading}
              contentPages={contentPagesData?.contentPages || []}
            />
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Grid item xs={8}>
              <BoatSEOTemplate
                onSave={save}
                siteMeta={siteMeta}
                setSiteMeta={setSiteMeta}
                loading={loading}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={6}>
            <DefaultLocationSelector
              locations={locationsData?.locations}
              siteMeta={siteMeta}
              setSiteMeta={setSiteMeta}
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={save}>
                Save Default Location
              </Button>
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={7}>
            <FooterCreator
              siteMeta={siteMeta}
              setSiteMeta={setSiteMeta}
              onSave={save}
            />
          </TabPanel>
          <TabPanel value={tab} index={8}>
            <ManualPayoutSettings
              siteMeta={siteMeta}
              setSiteMeta={setSiteMeta}
              onSave={save}
            />
          </TabPanel>
        </div>
      </Grid>
    </Grid>
  );
}

export default SiteMeta;
